<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    interface ProductDetails {
        name: string;
        value?: string | number | null;
        unit?: string;
    }

    const props = defineProps<{
        productDetails: ProductDetails[];
        tableHeader: string | null;
    }>();

    const { t } = useI18n();
</script>

<template>
    <table v-if="props.productDetails.length > 0" class="product-details-table">
        <thead v-if="tableHeader != null">
            <tr>
                <th>{{ tableHeader }}</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="detail in props.productDetails" :key="detail.name">
                <tr>
                    <th>{{ t(detail.name) }}</th>
                    <td>{{ detail.value }} {{ detail.unit }}</td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .product-details-table {
        width: 100%;
        font-size: main.$font-size-3;
        line-height: 1.2;

        tr {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-bottom: 1px solid #eaeaea;
            padding-top: main.$spacing-4;
            padding-bottom: main.$spacing-4;
            padding-left: main.$spacing-5;
            overflow-wrap: anywhere;

            th {
                width: 50%;
                text-align: left;
                font-weight: 500;
            }

            td {
                width: 50%;
                text-align: start;
            }
        }

        thead tr {
            th {
                font-weight: 700;
            }
        }
    }

    @include main.for-breakpoint-sm {
        .product-details-table {
            tr {
                th {
                    width: 35%;
                }

                td {
                    width: 65%;
                }
            }

            thead tr {
                padding-left: main.$spacing-4;
                background: main.$color-background-table-header;
            }
        }
    }
</style>
