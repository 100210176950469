<script setup lang="ts">
    import Popover from 'primevue/popover';
    import Button from 'primevue/button';
    import { useTemplateRef } from 'vue';

    type PopoverType = InstanceType<typeof Popover>;

    const deliveryDetailsOverlayPanel = useTemplateRef<PopoverType>('deliveryDetailsOverlayPanel');

    function toggleOverlayPanel(event: Event): void {
        deliveryDetailsOverlayPanel.value?.toggle(event);
    }
</script>

<template>
    <div>
        <Button type="button" class="icon-button" @click.stop="toggleOverlayPanel">
            <slot name="icon" />
        </Button>
        <Popover ref="deliveryDetailsOverlayPanel">
            <slot name="overlay-panel-content" />
        </Popover>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .icon-button {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 4px;
        margin: 0;

        border: none;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        cursor: pointer;
    }
</style>
